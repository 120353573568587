"use client";

import { Typography } from "@mui/material";
import { useLogger, LogLevel } from "next-axiom";
import { usePathname } from "next/navigation";
export default function ErrorPage({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const pathname = usePathname();
  const log = useLogger({
    source: "error.tsx"
  });
  let status = error.message == "Invalid URL" ? 404 : 500;
  log.logHttpRequest(LogLevel.error, error.message, {
    host: window.location.href,
    path: pathname,
    statusCode: status
  }, {
    error: error.name,
    cause: error.cause,
    stack: error.stack,
    digest: error.digest
  });
  return <Typography component="h2" variant="h6" sx={{
    color: "#EFF6E0"
  }} textAlign="center" my={2} data-sentry-element="Typography" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      Oops! an error has occurred
    </Typography>;
}